<template>
  <!-- <div class="bg-img"></div> -->
  <div class="custom-height p-4 p-xl-0">
    <div class="d-flex flex-column align-items-center" style="padding-top: 120px">
      <div class="text-center">
        <div class="heading-03 text-neutral-08">Site în construcție</div>
        <div class="heading-07 text-neutral-05">Pregătim bunătăți pentru tine</div>
        <img src="../assets/images/maintenance.webp" class="mt-5 maintenance-img"  alt="..."/>
      </div>
    </div>
  </div>
  <Footer class="pb-5 mb-3 pb-sm-0 mb-sm-0" />
</template>

<script>
import Footer from '../components/General/Footer.vue';

export default {
  name: 'ContactView ',
  data() {
    return {};
  },
  components: { Footer },
  methods: {},
};
</script>
<style scoped>
@media (min-width: 1200px) {
  .custom-height {
    height: calc(100vh - 64px - 101px);
  }
}
@media (max-width: 1200px) {
  .maintenance-img {
    width: 100%;
  }
}

.bg-img {
  position: absolute;
  bottom: 101px;
  width: 100%;
  height: 100vh;
  background-image: url('../assets/images/not-found-page-bg.webp');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  z-index: -1;
}
</style>
